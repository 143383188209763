@media (prefers-color-scheme: light) {
  :root {
    background-color: #FFFFFF;
    color: #0B1F33;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    background-color: #0E0E0F;
    color: #FDFDFD;
  }
}

body {
  margin: 0;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0;
  padding-top: 0;
}

* {
  box-sizing: border-box;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Toastify__toast-icon { 
  align-self: flex-start;
}
